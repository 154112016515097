import { useState, useEffect } from "react";
import _ from "underscore";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { LicenseInfo } from "@mui/x-license-pro";
import Box from "@mui/material/Box";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export default function ElectricDetail({ entity }) {
    const [pulse, setPlulse] = useState("");
    const [index, setIndex] = useState(0);

    useEffect(() => {
        // console.log("useEffect in ElectricDetail");
        // console.log(entity);
    }, []);

    return (
        <Paper elevation={3} sx={{ height: "100%" }}>
            <Box sx={{ display: "flex" }}>
                <Grid container spacing={2} sx={{ overflow: "hidden", height: "100%", p: 2 }}>
                    <Grid item xs={12}>
                        <TextField
                            id="txtIndex"
                            variant="outlined"
                            value={index}
                            onChange={(e) => setIndex(e.target.value)}
                            size="small"
                            type="number"
                            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                            label="Start index"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="txtPulse"
                            variant="outlined"
                            value={pulse}
                            onChange={(e) => setPlulse(e.target.value)}
                            fullWidth
                            size="small"
                            label="Pluse per KwH coeff"
                        />
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
}
