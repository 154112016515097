import React, { useEffect, useState, useContext } from "react";
import AppContext from "Components/AppContext";
import SettingContext from "Providers/SettingContext";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import ApplicationData, { AppId } from "Components/applications/ApplicationData";
import { useTranslation } from "react-i18next";
import MaxByRoomChart from "./MaxByRoomChart";
import HeatChart from "./HeatChart";
import SalleA from "./SalleA";
import Salle1 from "./Salle1";
import Salle2 from "./Salle2";
import Salle3 from "./Salle3";
import Salle4 from "./Salle4";
import Stack from "@mui/material/Stack";
import ElectricityPanel from "./ElectricityPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
highchartsMore(Highcharts);

var averagesA = [
    ["Siege-Salle 2", 1430],
    ["Siege-Salle 1", 1400],
    ["Siege-Salle A", 1200],
];

var averagesB = [
    ["Agence-Salle 3", 1230],
    ["Agence-Salle 4", 1100],
];

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function Analysis(props) {
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);
    // ctx.updateTitle("MultiMetering");
    const { t, i18n } = useTranslation();

    // const componentRef = useRef();
    const [value, setValue] = useState("0");
    const [chartData, setChartData] = useState([]);
    const [panelIndex, setPanelIndex] = useState(0);

    useEffect(() => {
        setting.setGlobalSetting({
            appId: AppId.EMERA_V2,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });
        ctx.updateTitle(ApplicationData.find((a) => a.id == AppId.EMERA_V2).title);

        return () => {
            ctx.updateTitle(null);
        };
    }, [value, chartData]);

    const handleRoom = (name) => {
        // console.log("handleRoom=" + name)

        if (name.indexOf("Salle A") != -1) {
            setChartData(SalleA.data);
        } else if (name.indexOf("Salle 1") != -1) {
            // console.log("Here !!");
            setChartData(Salle1.data);
        } else if (name.indexOf("Salle 2") != -1) {
            setChartData(Salle2.data);
        } else if (name.indexOf("Salle 3") != -1) {
            setChartData(Salle3.data);
        } else if (name.indexOf("Salle 4") != -1) {
            setChartData(Salle4.data);
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ pt: 10, ml: 2, mr: 2 }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <span style={{ fontFamily: "Days One", fontSize: "24px", fontWeight: "normal", color: "#9B9B9B" }}>{t("emera_v2.analysis")}</span>
                </Grid>
                <TabContext value={value}>
                    <Grid item xs={12}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Climate" value="0" {...a11yProps(0)} />
                            <Tab label="Electricity" value="1" {...a11yProps(1)} />
                            <Tab label="Water" value="2" {...a11yProps(2)} />
                        </Tabs>
                    </Grid>

                    <Grid item xs={12}>
                        <TabPanel value="0">
                            <Stack>
                                {value === "0" && (
                                    <Box sx={{ display: "flex" }}>
                                        <MaxByRoomChart siteName="Siege" averages={averagesA} callback={handleRoom} />
                                        <MaxByRoomChart siteName="Agence" averages={averagesB} callback={handleRoom} />
                                    </Box>
                                )}
                            </Stack>
                        </TabPanel>
                        <TabPanel value="1">
                            <ElectricityPanel />
                        </TabPanel>
                        <TabPanel value="2">{/* Water content */}</TabPanel>
                    </Grid>
                </TabContext>
            </Grid>
        </Box>
    );
}
