const RoomConstants = {
    rowsA: [
        createData(
            "Siege",
            "Salle A",
            entity(98, "%", "Aug 15, 09:01 PM"),
            entity(100, "ppm", "Aug 15, 09:01 PM"),
            entity(87, "ppm", "Jan 2", "Moyenne"),
            entity(29, "°C", "Aug 15, 09:01 PM"),
            entity(25, "°C", "Jan 2", "Moyenne"),
            entity(5, "%", "Aug 15, 09:01 PM"),
            entity(6, "%", "Jan 2", "Moyenne")
        ),

        createData(
            "Siege",
            "Salle 1",
            entity(90, "%", "Aug 15, 09:01 PM"),
            entity(1000, "ppm", "Aug 15, 09:01 PM"),
            entity(900, "ppm", "Jan 2", "Moyenne"),
            entity(31, "°C", "Aug 15, 09:01 PM"),
            entity(26, "°C", "Jan 2", "Moyenne"),
            entity(40, "%", "Aug 15, 09:01 PM"),
            entity(30, "%", "Jan 2", "Moyenne")
        ),

        createData(
            "Siege",
            "Salle 2",
            entity(90, "%", "Aug 15, 09:01 PM"),
            entity(1500, "ppm", "Aug 15, 09:01 PM"),
            entity(1400, "ppm", "Jan 2", "Moyenne"),
            entity(32, "°C", "Aug 15, 09:01 PM"),
            entity(27, "°C", "Jan 2", "Moyenne"),
            entity(55, "%", "Aug 15, 09:01 PM"),
            entity(40, "%", "Jan 2", "Moyenne")
        ),

        // sub counter
        createElectricity(
            "1",
            "Siege",
            "Salle A",
            "Counter 1",
            false,
            "8c83fc050059f61d",
            entity(93, "kWh", "Aug 15, 09:01 PM"),
            entity(91, "kWh", "Jan 2", "Moyenne")
        ),

        // master counter
        createElectricity(
            "2",
            "Siege",
            "Salle 1",
            "Counter 2",
            true,
            "0018b20000020c36",
            entity(93 + 101, "kWh", "Aug 15, 09:01 PM"),
            entity(91 + 102, "kWh", "Jan 2", "Moyenne")
        ),

        // sub counter
        createElectricity(
            "3",
            "Siege",
            "Salle 2",
            "Counter 3",
            false,
            "0018b20000020eec",
            entity(101, "kWh", "Aug 15, 09:01 PM"),
            entity(102, "kWh", "Jan 2", "Moyenne")
        ),

        // positions
        createPosition("Siege", "Salle A", 90, 100),
        createPosition("Siege", "Salle 1", 460, 80),
        createPosition("Siege", "Salle 2", 580, 400),
    ],

    rowsB: [
        createData(
            "Agence",
            "Salle 3",
            entity(100, "%", "Aug 15, 09:01 PM"),
            entity(1550, "ppm", "Aug 15, 09:01 PM"),
            entity(2100, "ppm", "Jan 2", "Moyenne"),
            entity(33, "°C", "Aug 15, 09:01 PM"),
            entity(27, "°C", "Jan 2", "Moyenne"),
            entity(60, "%", "Aug 15, 09:01 PM"),
            entity(45, "%", "Jan 2", "Moyenne")
        ),

        // createData(
        //     "Agence",
        //     "Salle 4",
        //     entity(97, "%", "Aug 15, 09:01 PM"),
        //     entity(500, "ppm", "Aug 15, 09:01 PM"),
        //     entity(700, "ppm", "Jan 2", "Moyenne"),
        //     entity(34, "°C", "Aug 15, 09:01 PM"),
        //     entity(28, "°C", "Jan 2", "Moyenne"),
        //     entity(54, "%", "Aug 15, 09:01 PM"),
        //     entity(44, "%", "Jan 2", "Moyenne")
        // ),

        // positions
        createPosition("Agence", "Salle 3", 260, 250),
        // createPosition("Agence", "Salle 4", 620, 380),
    ],
};

function createData(floor, name, pwr, co2, co2Ave, temperature, temperatureAve, humidity, humidityAve) {
    return { floor, name, pwr, co2, co2Ave, temperature, temperatureAve, humidity, humidityAve };
}

function createElectricity(id, floor, name, counterName, isMaster, devEui, electricity, electricityAve) {
    return { id, floor, name, counterName, isMaster, electricity, devEui, electricityAve };
}

function createPosition(floor, name, x, y) {
    return { floor, name, position: { x: x, y: y } };
}

function entity(value, unit, date, moyenne) {
    return { value, unit, date, moyenne };
}

export default RoomConstants;
