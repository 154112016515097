import React, { useState, useEffect } from "react";
import { Stage, Layer, Image } from "react-konva";
import Box from "@mui/material/Box";
import useImage from "use-image";
import ImgFront from "Assets/icons/icon_electric_meter.png";
import ImgSiege from "Assets/images/DataCenter_room.png";
import ImgAgence from "Assets/images/floor_agence.jpg";
import KonvaImage from "./KonvaImage";
import { v4 as uuidv4 } from "uuid";
import Grid from "@mui/material/Grid";
import { AppId } from "Components/applications/ApplicationData";
import ImageService from "Services/imageService";
import CustomStyledButton from "Components/ui/CustomStyledButton";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import VisiblityMain from "./setting/VisiblityMain";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import CreateIcon from "@mui/icons-material/Create";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import Typography from "@mui/material/Typography";

export default function KonvaCanvas({ name, data, setReloadParent }) {
    const { t, i18n } = useTranslation();
    const [imgFront] = useImage(ImgFront);
    const [imgSiege] = useImage(ImgSiege);
    const [imgAgence] = useImage(ImgAgence);

    const [rooms, setRooms] = useState([]);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [scale, setScale] = useState(1);

    const [code, setCode] = useState();
    const [backgroundImage, setBackgroundImage] = useState();
    const [loaded, setLoaded] = useState(false);
    const [openVisibleSetting, setOpenVisibleSetting] = useState(false);

    useEffect(() => {
        // console.log("useEffect in KonvaCanvas");
        // console.log(name, data);

        if (data) {
            const groupedData = data.reduce((acc, obj) => {
                const name = obj.name;
                if (!acc[name]) {
                    acc[name] = [];
                }
                acc[name].push(obj);
                return acc;
            }, {});

            let temp = [];
            for (let key in groupedData) {
                temp.push(groupedData[key]);
            }

            setRooms(temp);
        }

        let newWidth, newHeight, newImageCode;
        if (name === "Siege") {
            newWidth = 815;
            newHeight = 518;
            newImageCode = AppId.EMERA_V2 + "_siege_bg_image";
        } else {
            newWidth = 870;
            newHeight = 505;
            newImageCode = AppId.EMERA_V2 + "_agence_bg_image";
        }

        if (!backgroundImage && newImageCode !== code) {
            setWidth(newWidth);
            setHeight(newHeight);
            setCode(newImageCode);

            ImageService.requestBackgroundImage(newImageCode, setBackgroundImage).then((r) => {
                if (r) {
                    const imgElement = document.createElement("img");
                    imgElement.src = "data:image/png;base64," + r;
                    setBackgroundImage(imgElement);
                }
                setLoaded(true);
            });
        }
    }, [name, data, backgroundImage, loaded, code]);

    const handleImage = (e) => {
        ImageService.uploadBackgroundImage(code, e.target.files[0], setBackgroundImage).then((response) => {
            setCode(undefined);
            setBackgroundImage(undefined);
        });
    };

    const zoomIn = () => {
        setScale((prevScale) => Math.min(prevScale * 1.2, 5));
    };

    const zoomOut = () => {
        setScale((prevScale) => Math.max(prevScale / 1.2, 0.2));
    };

    return (
        <Box sx={{ flexGrow: 1, pt: 5, display: "flex", justifyContent: "center" }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="h5">{name}</Typography>
                </Grid>
                <Grid item xs={12} sx={{ gap: 1, display: "flex", flexDirection: "row" }}>
                    <CustomStyledButton icon={<ZoomInIcon />} callback={zoomIn} />
                    <CustomStyledButton icon={<ZoomOutIcon />} callback={zoomOut} />

                    <Tooltip title="Image">
                        <label htmlFor="contained-button-file">
                            <input style={{ display: "none" }} accept="image/*" id="contained-button-file" type="file" onChange={handleImage} />
                            <CustomStyledButton component="span" icon={<AddPhotoAlternateIcon />} />
                        </label>
                    </Tooltip>

                    <Tooltip title={t("superviot.add")}>
                        <label>
                            <CustomStyledButton icon={<CreateIcon />} callback={() => setOpenVisibleSetting(true)} />
                        </label>
                    </Tooltip>
                </Grid>

                <Grid item xs={12} sx={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "flex-start", height: "100%" }}>
                    <Stage width={width} height={height} scaleX={scale} scaleY={scale} draggable>
                        <Layer>
                            {!backgroundImage && loaded && name == "Siege" && <Image image={imgSiege} />}
                            {!backgroundImage && loaded && name == "Agence" && <Image image={imgAgence} />}
                            {backgroundImage && loaded && <Image image={backgroundImage} />}
                        </Layer>
                        <Layer>
                            {loaded &&
                                rooms &&
                                rooms.map((r) => {
                                    return <KonvaImage data={r} image={imgFront} key={uuidv4()} />;
                                })}
                        </Layer>
                    </Stage>
                </Grid>
            </Grid>

            <VisiblityMain open={openVisibleSetting} onClose={() => setOpenVisibleSetting(false)} setReloadParent={setReloadParent} />
        </Box>
    );
}
