import React, { useEffect, useState, useContext } from "react";
import { Box } from "@mui/material";
import IslandMap from "./island/IslandMap";
import SideManu from "./island/sidebar/SideManu";
import AppContext from "Components/AppContext";
import UserService from "Services/userService";
import MeasurementServiceV2 from "Routes/deviceExplorer/measurements/MeasurementServiceV2";
import dayjs from "dayjs";

export default function SuperviotMain() {
    const [positions, setPositions] = useState();
    const [selectedPosition, setSelectedPosition] = useState();
    const [reload, setReload] = useState();
    const ctx = useContext(AppContext);

    useEffect(() => {
        ctx.updateTitle("Superviot");
    }, [ctx]);

    useEffect(() => {
        UserService.getAuthenticatedUser().then((user) => {
            console.log("user", user);

            const requestFunction =
                user.authorities === "USER"
                    ? () => MeasurementServiceV2.requestByUser("", 0, 100, undefined, undefined, [{ field: "name", sort: "DESC" }], user.username)
                    : () => MeasurementServiceV2.requestData("", 0, 100, undefined, undefined, [{ field: "name", sort: "DESC" }]);

            requestFunction().then((r) => {
                const mapped = r
                    .map((item) => {
                        // visibility : default true, if SUPERVIOT_ICON_VISIBILITY is false, don't show the item
                        let visibility = item.customFields?.find((c) => c.name === "SUPERVIOT_ICON_VISIBILITY")?.value;
                        visibility = visibility === undefined ? true : visibility === "false" ? false : Boolean(visibility);

                        return {
                            name: item.name,
                            thingId: item.id,
                            siteName: item["site.name"],
                            visibility,
                        };
                    })
                    .filter((item) => {
                        // if SUPERVIOT_ICON_VISIBILITY is false, don't show the item
                        return item.visibility;
                    });

                setPositions(mapped);
            });
        });
    }, [selectedPosition, reload]);

    return (
        <Box sx={{ pt: 10, ml: 2, mr: 2, display: "flex" }}>
            <IslandMap positions={positions} callback={(p) => setSelectedPosition(p)} setReloadParent={() => setReload(dayjs().valueOf())} />
            <SideManu position={selectedPosition} />
        </Box>
    );
}
