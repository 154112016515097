import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import AppContext from "Components/AppContext";
import { useTranslation } from "react-i18next";
import SettingContext from "Providers/SettingContext";
import ApplicationData, { AppId } from "Components/applications/ApplicationData";
import RoomConstants from "../RoomConstants";
import { Box } from "@mui/material";
import KonvaCanvas from "./KonvaCanvas";

export default function Floor() {
    const ctx = useContext(AppContext);
    const setting = useContext(SettingContext);

    const { t, i18n } = useTranslation();
    let { name } = useParams();

    const [data, setData] = useState();

    useEffect(() => {
        setting.setGlobalSetting({
            appId: AppId.EMERA_V2,
            temperatureMonitoring: {
                celsius: true,
                language: "en",
            },
        });
        ctx.updateTitle(ApplicationData.find((a) => a.id === AppId.EMERA_V2).title);

        if (name === "Siege") {
            setData(RoomConstants.rowsA);
        } else if (name === "Agence") {
            setData(RoomConstants.rowsB);
        }
    }, []);

    return (
        <Box sx={{ padding: "56px" }}>
            <KonvaCanvas name={name} data={data} />
        </Box>
    );
}
