import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CustomStyledButton from "Components/ui/CustomStyledButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import AddStationService from "./AddStationService";
import validator from "validator";
import UserService from "Services/userService";

const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

function AddStationPopup({ open, onClose }) {
    const { t, i18n } = useTranslation();

    const [companyId, setCompanyId] = useState("");
    const [name, setName] = useState("");
    const [fullName, setFullName] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");

    const [errors, setErrors] = useState({
        name: false,
        fullName: false,
        latitude: false,
        longitude: false,
    });

    useEffect(() => {
        UserService.getAuthenticatedUser().then((user) => {
            // console.log("user", user);
            setCompanyId(user.companyId);
        });
    }, [open]);

    const handleAdd = () => {
        const newErrors = {
            name: validator.isEmpty(name),
            fullName: validator.isEmpty(fullName),
            latitude: latitude && !validator.isFloat(latitude),
            longitude: longitude && !validator.isFloat(longitude),
        };
        setErrors(newErrors);

        if (Object.values(newErrors).some((error) => error)) {
            return;
        }

        AddStationService.addStation(companyId, name, fullName, latitude, longitude).then(() => {
            setName("");
            setFullName("");
            setLatitude("");
            setLongitude("");
            onClose();
        });
    };

    return (
        <StyledDialog onClose={onClose} open={open}>
            <DialogTitle>
                {t("emera_v2.add_sensor")}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers sx={{ m: 2 }}>
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                    <Grid item xs={3}>
                        <Typography>{t("superviot.name")}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            error={errors.name}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>{t("superviot.full_name")}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            margin="dense"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            error={errors.fullName}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>{t("superviot.latitude")}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            margin="dense"
                            type="number"
                            fullWidth
                            variant="outlined"
                            value={latitude}
                            onChange={(e) => setLatitude(e.target.value)}
                            error={errors.latitude}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>{t("superviot.longitude")}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            margin="dense"
                            type="number"
                            fullWidth
                            variant="outlined"
                            value={longitude}
                            onChange={(e) => setLongitude(e.target.value)}
                            error={errors.longitude}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ mr: 2, mb: 2 }}>
                <CustomStyledButton callback={handleAdd} name={t("btn.add")} />
            </DialogActions>
        </StyledDialog>
    );
}

export default AddStationPopup;
