import { useState } from "react";
import _ from "underscore";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { LicenseInfo } from "@mui/x-license-pro";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import MenuItem from "@mui/material/MenuItem";
import CustomStyle from "../../../components/CustomStyle";
import FormControlLabel from "@mui/material/FormControlLabel";
import dayjs from "dayjs";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export default function CustomerDetail({ selectedThingId, setReload, thing }) {
    const [value, setValue] = useState(dayjs());
    const [isMaster, setIsMaster] = useState(true);
    const [counter, setCounter] = useState(10);

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    const handleMaster = (e) => {
        // console.log("handleMaster....");
        // console.log(e.target.checked);
        setIsMaster(e.target.checked);
    };

    const handleCounter = (e) => {
        // console.log("handleCounter....");
        // console.log(e);
        setCounter(e.target.value);
    };

    return (
        <Paper elevation={3} sx={{ height: "100%" }}>
            <Box sx={{ display: "flex" }}>
                <Grid container sx={{ overflow: "hidden", height: "100%", p: 2 }} spacing={2}>
                    {/* <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="Date"
                                inputFormat="MM/dd/yyyy"
                                value={value}
                                onChange={handleChange}
                                slotProps={{ textField: { variant: "outlined", size: "small" } }} 
                            />
                        </LocalizationProvider>
                    </Grid> */}

                    <Grid item xs={12}>
                        <FormControlLabel
                            value="start"
                            control={<Switch checked={isMaster} onChange={(e) => handleMaster(e)} inputProps={{ "aria-label": "controlled" }} />}
                            label="Master counter"
                            labelPlacement="start"
                        />

                        {!isMaster && (
                            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
                                <FormControl sx={{ width: 200 }}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={counter}
                                        onChange={(e) => handleCounter(e)}
                                        size="small"
                                        sx={CustomStyle.RemoveLabel}
                                    >
                                        <MenuItem value={10}>Counter 1</MenuItem>
                                        <MenuItem value={20}>Counter 2</MenuItem>
                                        <MenuItem value={30}>Counter 3</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
}
