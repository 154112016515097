import Switch from "@mui/material/Switch";

export const getVisibleSettingColumns = (t, handleVisibilityChange) => [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "name", headerName: t("emera_v2.sensor"), flex: 1 },
    {
        field: "visible",
        headerName: t("superviot.visibility"),
        flex: 1,
        renderCell: (params) => <Switch checked={params.value} onChange={() => handleVisibilityChange(params.row.id)} />,
    },
];
